import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Footer from '../components/Shared/Footer'


const ContactUs = () => {
  return (
    <body>
     
    <main>
    <section class="hero-contact">
      <div class="hero-content">
        <h1>Contact TheVillaLife's Private Jets</h1>
        <p>Let us elevate your travel experience</p>
      </div>
    </section>

    <section class="contact-section">
      <div class="container">
        <div class="contact-container">
          <div class="contact-form">
            <h2 class="section-title">Get in Touch</h2>
            
            <HubspotForm
                region= "na1"
                portalId='21601021'
                formId='74021eab-7a28-40a4-afb1-13a8334d2fde'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
            {/* <form action="https://events.thevillalife.com/submit-contact" method="POST">
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div class="form-group">
                <label for="phone">Phone</label>
                <input type="tel" id="phone" name="phone" />
              </div>
              <div class="form-group">
                <label for="company">Company</label>
                <input type="text" id="company" name="company" />
              </div>
              <div class="form-group">
                <label for="message">Message</label>
                <textarea id="message" name="message" required></textarea>
              </div>
              <button type="submit" class="submit-btn">Send Message</button>
            </form> */}
          </div>
          <div class="contact-info">
            <h2 class="section-title">Contact Information</h2>
            <div class="contact-info-item">
              <i class="fas fa-map-marker-alt"></i>
              <p>500-100 University Avenue, Toronto, ON</p>
            </div>
            <div class="contact-info-item">
              <i class="fas fa-phone"></i>
              <p>(833) 838-2388</p>
            </div>
            <div class="contact-info-item">
              <i class="fas fa-envelope"></i>
              <p>sales@thevillalife.com</p>
            </div>
            <h3>Office Hours</h3>
            <p>Monday - Friday: 9:00 AM - 6:00 PM</p>
            <p>Saturday: 10:00 AM - 4:00 PM</p>
            <p>Sunday: Closed</p>
            <div class="social-links">
              <a href="https://facebook.com/thevillalife" target="_blank"><i class="fab fa-facebook"></i></a>
              <a href="https://twitter.com/thevillalife" target="_blank"><i class="fab fa-twitter"></i></a>
              <a href="https://instagram.com/thevillalife" target="_blank"><i class="fab fa-instagram"></i></a>
              <a href="https://linkedin.com/company/thevillalife" target="_blank"><i class="fab fa-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</body>
  )
}

export default ContactUs