import React from 'react'
import Footer from '../components/Shared/Footer'

const Events = () => {
  const events=[{
    url:'conferenceHall.avif',
    date:'July 15, 2023',
    title:'Luxury Rental Summit 2023',
    description:'Join industry leaders for a day of insights, networking, and innovation in the luxury rental market. Discover the latest trends and strategies to elevate your business.',
    link:'/events/luxury-rental-summit-2023'
  },
  {
    url:'gathering.avif',
    date:'August 19, 2023',
    title:'VillaLife Pool Party',
    description:'Celebrate summer with a luxurious pool party at the VillaLife Mansion. Enjoy an afternoon of music, drinks, and networking with industry professionals.',
    link:'/events/villalife-pool-party'
  },
  {
    url:'gathering1.avif',
    date:'September 23, 2023',
    title:'Beach Retreat Weekend',
    description:'Relax and recharge at the VillaLife Beach Retreat. Spend the weekend in a luxurious beachfront villa, enjoy gourmet meals, and connect with other luxury rental professionals.',
    link:'/events/beach-retreat-weekend'
  },
  {
    url:'gathering2.avif',
    date:'December 15, 2023',
    title:'Holiday Gala Dinner',
    description:'Celebrate the holiday season in style at the VillaLife Holiday Gala Dinner. Enjoy an evening of fine dining, entertainment, and festive cheer with industry colleagues.',
    link:'/events/holiday-gala-dinner'
  },
  {
    url:'presentation.avif',
    date:'July 15, 2023',
    title:'Luxury Rental Summit 2023',
    description:'Join industry leaders for a day of insights, networking, and innovation in the luxury rental market. Discover the latest trends and strategies to elevate your business.',
    link:'/events/luxury-rental-summit-2023'
  },
  {
    url:'presentation1.avif',
    date:'August 19, 2023',
    title:'VillaLife Pool Party',
    description:'Celebrate summer with a luxurious pool party at the VillaLife Mansion. Enjoy an afternoon of music, drinks, and networking with industry professionals.',
    link:'/events/villalife-pool-party'
  },
  {
    url:'presentation2.avif',
    date:'September 23, 2023',
    title:'Beach Retreat Weekend',
    description:'Relax and recharge at the VillaLife Beach Retreat. Spend the weekend in a luxurious beachfront villa, enjoy gourmet meals, and connect with other luxury rental professionals.',
    link:'/events/beach-retreat-weekend'
  },
  {
    url:'holidayGala.avif',
    date:'December 15, 2023',
    title:'Holiday Gala Dinner',
    description:'Celebrate the holiday season in style at the VillaLife Holiday Gala Dinner. Enjoy an evening of fine dining, entertainment, and festive cheer with industry colleagues.',
    link:'/events/holiday-gala-dinner'
  },
  {
    url:'conferenceHall.avif',
    date:'July 15, 2023',
    title:'Luxury Rental Summit 2023',
    description:'Join industry leaders for a day of insights, networking, and innovation in the luxury rental market. Discover the latest trends and strategies to elevate your business.',
    link:'/events/luxury-rental-summit-2023'
  },

]
  return (
    <body>
  <main>
    <section id="home" class="hero-jets">
      <div class="hero-content">
        <h1>Experience Luxury in the Sky</h1>
        <p>Elevate your travel with The Villa Life Private Jets</p>
        <a href="/contact" class="cta-button">Book Your Flight</a>
      </div>
    </section>

    <section id="services" class="services-jets">
      <div class="container">
        <h2>Our Services</h2>
        <div class="service-grid-jets">
          <div class="service-card-jets">
            <img src="jet-service1.avif" alt="Private Jet Charter" width="300" height="200"/>
            <div class="service-card-content-jets">
              <h3>Private Jet Charter</h3>
              <p>Experience the ultimate in luxury travel with our on-demand private jet charter service.</p>
            </div>
          </div>
          <div class="service-card-jets">
            <img src="jet-service2.avif" alt="Corporate Travel" width="300" height="200"/>
            <div class="service-card-content-jets">
              <h3>Corporate Travel</h3>
              <p>Efficient and flexible solutions for your business travel needs, enhancing productivity and comfort.</p>
            </div>
          </div>
          <div class="service-card-jets">
            <img src="jet-service3.avif" alt="Luxury Vacations" width="300" height="200"/>
            <div class="service-card-content-jets">
              <h3>Luxury Vacations</h3>
              <p>Curated luxury vacation packages with private jet transportation to exclusive destinations worldwide.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="fleet" class="fleet-jets">
      <div class="container">
        <h2>Our Fleet</h2>
        <div class="fleet-grid-jets">
          <div class="fleet-card-jets">
            <img src="jet-fleet1.avif" alt="Light Jet" width="300" height="200"/>
            <div class="fleet-card-content-jets">
              <h3>Light Jet</h3>
              <p>Perfect for short trips and small groups.</p>
              <ul>
                <li>Seats: Up to 8 passengers</li>
                <li>Range: Up to 2,000 miles</li>
                <li>Speed: Up to 500 mph</li>
              </ul>
            </div>
          </div>
          <div class="fleet-card-jets">
            <img src="jet-fleet2.avif" alt="Midsize Jet" width="300" height="200"/>
            <div class="fleet-card-content-jets">
              <h3>Midsize Jet</h3>
              <p>Ideal for longer flights and larger groups.</p>
              <ul>
                <li>Seats: Up to 10 passengers</li>
                <li>Range: Up to 3,000 miles</li>
                <li>Speed: Up to 550 mph</li>
              </ul>
            </div>
          </div>
          <div class="fleet-card-jets">
            <img src="jet-fleet3.avif" alt="Heavy Jet" width="300" height="200"/>
            <div class="fleet-card-content-jets">
              <h3>Heavy Jet</h3>
              <p>Luxurious and spacious for long-haul flights.</p>
              <ul>
                <li>Seats: Up to 16 passengers</li>
                <li>Range: Up to 6,000 miles</li>
                <li>Speed: Up to 600 mph</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="why-choose-us" class="why-choose-us">
      <div class="container">
        <h2>Why Choose The Villa Life Private Jets</h2>
        <div class="reasons-grid">
          <div class="reason-card">
            <i class="fas fa-clock"></i>
            <h3>Time-Saving</h3>
            <p>Skip long security lines and fly on your schedule.</p>
          </div>
          <div class="reason-card">
            <i class="fas fa-user-tie"></i>
            <h3>Personalized Service</h3>
            <p>Tailored experiences to meet your unique needs.</p>
          </div>
          <div class="reason-card">
            <i class="fas fa-shield-alt"></i>
            <h3>Safety First</h3>
            <p>Rigorous safety standards and experienced crew.</p>
          </div>
          <div class="reason-card">
            <i class="fas fa-globe"></i>
            <h3>Global Access</h3>
            <p>Reach remote destinations and exclusive locations.</p>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</body>
  )
}

export default Events