import React from 'react'
import CorporateEventCard from '../components/Cards/CorporateEventCard'

const Weddings = () => {
  const events=[{
    url:'conferenceHall.avif',
    icon:'🏖️',
    date:'July 15, 2023',
    title:'Breathtaking Destinations',
    description:'Immerse your team in awe-inspiring locations that spark creativity and foster connection. From secluded beaches to mountain retreats, we curate environments that inspire greatness.',
    link:'https://events.thevillalife.com/events/luxury-rental-summit-2023'
  },
  {
    url:'gathering.avif',
    icon:'🎉',
    date:'August 19, 2023',
    title:'Unforgettable Experiences',
    description:'Elevate your corporate retreat with unforgettable experiences that bring your team closer together. From private chef dinners to luxury yacht charters, we create moments that last a lifetime.',
    link:'https://events.thevillalife.com/events/villalife-pool-party'
  },
  {
    url:'gathering1.avif',
    icon:'🌟',
    date:'September 23, 2023',
    title:'Luxury Accommodations',
    description:'Experience the height of luxury with our handpicked selection of villas, mansions, and estates. Each property is designed to provide the ultimate in comfort, privacy, and relaxation.',
    link:'https://events.thevillalife.com/events/beach-retreat-weekend'
  },
  {
    url:'gathering2.avif',
    icon:'🍸',
    date:'December 15, 2023',
    title:'World-Class Dining',
    description:'Indulge in gourmet meals prepared by our team of private chefs. From breakfast buffets to multi-course dinners, we offer a range of dining options to suit every taste and preference.',
    link:'https://events.thevillalife.com/events/holiday-gala-dinner'
  },
  {
    url:'presentation.avif',
    icon:'🎤',
    date:'July 15, 2023',
    title:'Expert Speakers',
    description:'Learn from industry experts, thought leaders, and innovators who share their knowledge and insights on a wide range of topics. Our speakers inspire, educate, and motivate your team to succeed.',
    link:'https://events.thevillalife.com/events/luxury-rental-summit-2023'
  },
  {
    url:'presentation1.avif',
    icon:'🎶',
    date:'August 19, 2023',
    title:'Team-Building Activities',
    description:'Strengthen your team with engaging and interactive team-building activities. From scavenger hunts to cooking classes, we offer a variety of activities that promote collaboration and communication.',
    link:'https://events.thevillalife.com/events/villalife-pool-party'
  },
  {
    url:'presentation2.avif',
      icon:'🏄',
      date:'September 23, 2023',
      title:'Wellness & Relaxation',
      description:'Prioritize your team\'s well-being with wellness and relaxation activities. From yoga classes to spa treatments, we offer a range of options to help your team unwind and recharge.',
      link:'https://events.thevillalife.com/events/beach-retreat-weekend'
  },
  {
    url:'holidayGala.avif',
    icon:'🎄',
    date:'December 15, 2023',
    title:'Holiday Celebrations',
    description:'Celebrate the holiday season with festive events and activities. From themed parties to gift exchanges, we create memorable experiences that bring joy and cheer to your team.',
    link:'https://events.thevillalife.com/events/holiday-gala-dinner'
  },
  {
    url:'conferenceHall.avif',
    icon:'🏖️',
    date:'July 15, 2023',
    title:'Breathtaking Destinations',
    description:'Immerse your team in awe-inspiring locations that spark creativity and foster connection. From secluded beaches to mountain retreats, we curate environments that inspire greatness.',
    link:'https://events.thevillalife.com/events/luxury-rental-summit-2023'
  }

]
  return (
    <body>
  <main>
    <section className="hero-marriage">
      <div className="hero-content flex justify-center flex-col">
        {/* <div className=' text-6xl font-semibold tracking-wide'>Thevillalife Events</div> */}
        <h1>Your Dream Wedding Awaits</h1>
        <p>Exquisite celebrations tailored for your perfect day</p>
        <a href="/weddings/consult" class="cta-button">Start Planning Your Wedding</a>
      </div>
    </section>
    
    {/* <section className="events-section">
      <div className="container">
        <div className="events-grid">
          {events.map((event, index) => (
            <CorporateEventCard key={index} {...event} />
          ))}
        </div>
      </div>
    </section> */}
    <section class="features">
      <div class="container">
        <h2>Why Choose TheVillaLife for Your Special Day</h2>
        <div class="features-grid">
          <div class="feature-card">
            <i class="fas fa-ring"></i>
            <h3>Breathtaking Venues</h3>
            <p>From beachfront paradises to elegant ballrooms, we offer stunning locations for your perfect wedding.</p>
          </div>
          <div class="feature-card">
            <i class="fas fa-magic"></i>
            <h3>Personalized Planning</h3>
            <p>Our expert wedding planners ensure every detail of your day is tailored to your unique vision.</p>
          </div>
          <div class="feature-card">
            <i class="fas fa-utensils"></i>
            <h3>Gourmet Catering</h3>
            <p>Delight your guests with exquisite cuisine crafted by our world-class chefs.</p>
          </div>
          <div class="feature-card">
            <i class="fas fa-camera-retro"></i>
            <h3>Memories Forever</h3>
            <p>Capture every magical moment with our professional photography and videography services.</p>
          </div>
          <div class="feature-card">
            <i class="fas fa-heart"></i>
            <h3>Unforgettable Experiences</h3>
            <p>From live music to fireworks displays, we create unforgettable moments that last a lifetime.</p>
          </div>
          <div class="feature-card">
            <i class="fas fa-users"></i>
            <h3>World-Class Service</h3>
            <p>Our dedicated team provides exceptional service to ensure your day is flawless from start to finish.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="testimonials">
      <div class="container">
        <h2>Love Stories from Our Couples</h2>
        <div class="testimonial-grid">
          <div class="testimonial-card">
            <img src="weddings.avif" alt="Happy couple on their wedding day"/>
            <p>"TheVillaLife made our dream wedding a reality. The attention to detail and personalized service exceeded all our expectations."</p>
            <h4>Emily & James</h4>
          </div>
          <div class="testimonial-card">
            <img src="alex-sophie.avif" alt="Newlyweds sharing a romantic moment" />
            <p>"From the stunning venue to the impeccable service, our TheVillaLife wedding was absolutely magical. We couldn't have asked for more!"</p>
            <h4>Sophie & Alexander</h4>
          </div>
          <div class="testimonial-card">
            <img src="olivia-ethan.avif" alt="Couple exchanging vows" />
            <p>"TheVillaLife's team went above and beyond to create the wedding of our dreams. Every moment was perfect, and our guests are still talking about it!"</p>
            <h4>Olivia & Ethan</h4>
          </div>
        </div>
      </div>
    </section>

  </main>
  
  <footer className="footer">
    <div className="container">
      <p>&copy; 2024 Thevillalife. All rights reserved.</p>
      <p>
        <a href="https://events.thevillalife.com/privacy">Privacy Policy</a> | 
        <a href="https://events.thevillalife.com/terms">Terms of Service</a> |
        <a href="https://events.thevillalife.com/contact">Contact Us</a>
      </p>
    </div>
  </footer>
</body>
  )
}

export default Weddings