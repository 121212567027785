import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">
    <div className="container">
      <p>&copy; 2024 Thevillalife. All rights reserved.</p>
      <p>
        <a href="https://www.thevillalife.com/privacy-policy">Privacy Policy</a> | 
        <a href="https://www.thevillalife.com/terms-and-conditions">Terms of Service</a> |
        <a href="/contact">Contact Us</a>
      </p>
    </div>
  </footer>
  )
}

export default Footer